import React, {Component} from "react";
import {Button, Grid, Header, Image, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import {formatQuery} from "../controllers/utilities/string-utils";
import {getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall} from "../components/lib/ad-manage-api-lib";
import {navigate} from "../../.cache/gatsby-browser-entry";
import {getUA} from "react-device-detect";
import {AdAregionSwipetoslide} from "../components/react-slick/ad-aregion-swipetoslide";
import {AdBcdregionSwipetoslide} from "../components/react-slick/ad-bcdregion-swipetoslide";


export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userId:'',
            gameId:'',
            eventId: '',
            accessToken: '',
            nickname:'',
            createdAt:'',
            expireAt:'',
            expireAt_TTL:'',
            divStyle: '',
            adItem: {
                success: false,
                adId:'',
                eventId:'',
                adItem1: {
                    adItems:[]
                },
                adItem2: {
                    adItems:[]
                },
                adItem3: {
                    adItems:[]
                },
                adItem4: {
                    adItems:[]
                },
                adImage:'',
                adUrl:'',
                adAImage:'',
                adAUrl:'',
                adBImage:'',
                adBUrl:'',
                adCImage:'',
                adCUrl:''
            }
        };
        this.state.divStyle = {
            width: '289px',
            borderRadius: '10px 10px 0px 0px',
            overflow: 'hidden',
            display: ''
        };
        let { eventId, userId ,gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL} = formatQuery(this.props.location.search);
        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        if (eventId === undefined) eventId = "";
        this.state.eventId = eventId;
        console.log(this.props.location.search);
        console.log("123");
        console.log(eventId);
        // rejectIfAnyNull(this.props.session, this.props.session);
    }

    async showAd(sType, Url) {
        if (Url !== "") {
            const url = Url + "&rr1=download";
            //const uuid = Buffer.from(url).toString('base64');
            const uuid = encodeURIComponent(Buffer.from(url).toString('base64'));
            console.log(uuid);
            const newurl = `/gotoad/?eventId=${this.state.adItem.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}&expireAt_TTL=${this.state.expireAt_TTL}&uuid=${uuid}&r1=download`;
            console.log(newurl);
            let issdkorapp = false;
            issdkorapp = getUA.includes("JGGSDKWebView");
            if (issdkorapp){
                window.location.href = newurl;
            }else{
                window.open(url);
            }
            // setAdItem(this.state.userId, this.state.adItem.eventId, sType);
        }
    }

    async closeAd() {
        // setAdItem(this.state.userId,this.state.adItem.eventId,"");
    }

    async componentDidMount(){
        if (this.state.adItem.success === false) {
            let getdate = false;
            if (this.state.eventId !== ""){
                this.state.adItem = await getAdItemByEventId(this.state.eventId);
                getdate = this.state.adItem.success;
            }
            if (getdate === false){
                this.state.adItem = await getAdItem(this.state.userId, 0, 0);
            }
        }
        let issdkorapp = false;
        issdkorapp = getUA.includes("JGGSDKWebView");
        if (this.state.adItem.success === false){
            if (issdkorapp){
                AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt,expireAt_TTL: this.state.expireAt_TTL});
            }else{
                window.close();
            }
        }else{
            this.setState({
                divStyle: {
                    width: '289px',
                    borderRadius: '10px 10px 0px 0px',
                    overflow: 'hidden',
                    display: ''
                }
            });
        }
    }

    render() {
        return (

                                <div style={this.state.divStyle}>

                                <div style={{width:'289px',borderRadius:'10px 10px 0px 0px'}}
                                     >
                                    <AdAregionSwipetoslide adItems={this.state.adItem.adItem1.adItems} eventId={this.state.adItem.eventId} gameId={this.state.gameId} accessToken={this.state.accessToken} userId={this.state.userId} nickname={this.state.nickname} createdAt={this.state.createdAt} expireAt={this.state.expireAt} expireAt_TTL={this.state.expireAt_TTL}/>
                                </div>
                                    <div style={{padding:"7px 8px 0px 8px"}}
                                         >
                                        <AdBcdregionSwipetoslide adItems={this.state.adItem.adItem2.adItems} adClass="B" eventId={this.state.adItem.eventId} gameId={this.state.gameId} accessToken={this.state.accessToken} userId={this.state.userId} nickname={this.state.nickname} createdAt={this.state.createdAt} expireAt={this.state.expireAt} expireAt_TTL={this.state.expireAt_TTL}/>
                                    </div>
                                    <div style={{padding:"4px 8px 0px 8px"}}
                                         >
                                        <AdBcdregionSwipetoslide adItems={this.state.adItem.adItem3.adItems} adClass="C" eventId={this.state.adItem.eventId} gameId={this.state.gameId} accessToken={this.state.accessToken} userId={this.state.userId} nickname={this.state.nickname} createdAt={this.state.createdAt} expireAt={this.state.expireAt} expireAt_TTL={this.state.expireAt_TTL}/>

                                    </div>
                                    <div style={{padding:"4px 8px 7px 8px"}}
                                         >
                                        <AdBcdregionSwipetoslide adItems={this.state.adItem.adItem4.adItems} adClass="D" eventId={this.state.adItem.eventId} gameId={this.state.gameId} accessToken={this.state.accessToken} userId={this.state.userId} nickname={this.state.nickname} createdAt={this.state.createdAt} expireAt={this.state.expireAt} expireAt_TTL={this.state.expireAt_TTL}/>
                                    </div>


                                </div>
        );
    }
});